import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import Logo from "../images/logo.png";

const SubMenu = ({ link }) => {
  const [isSubmenuOpened, toggleSubmenu] = useState(false);

  return (
    <div
      className="relative mr-5 inline-block"
      onMouseEnter={() => toggleSubmenu(!isSubmenuOpened)}
      onMouseLeave={() => toggleSubmenu(!isSubmenuOpened)}
    >
      <Link
        className="font-medium hover:text-green-500 focus:outline-none focus:text-green-500 transition duration-150 ease-in-out"
        activeClassName="text-green-500"
        key={link.name}
        title={link.name}
        to={link.url}
        aria-label={link.name}
        id={link.name}
        aria-haspopup="true"
        aria-expanded={`${isSubmenuOpened ? `true` : `false`}`}
      >
        {link.name}
      </Link>
      <div
        className={`${
          isSubmenuOpened ? `block` : `hidden`
        } absolute left-1/2 transform -translate-x-1/2 px-0 mt-0 w-56 z-50`}
      >
        <div className="rounded-md shadow-lg mt-2">
          <div className="rounded-md bg-white shadow-xs">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby={link.name}
            >
              {link.submenu.map((item) => (
                <Link
                  className="block px-4 py-1 font-medium text-gray-900 hover:text-green-500 focus:outline-none focus:text-green-500 transition duration-150 ease-in-out"
                  activeClassName="text-green-500"
                  key={item.name}
                  title={item.name}
                  to={item.url}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  const menuItems = [
    {
      url: `/nos-solutions-anti-pigeons/`,
      name: `Nos Solutions`,
      submenu: [
        {
          url: `/picot-anti-pigeons/`,
          name: `Le picot`,
        },
        {
          url: `/filet-anti-pigeons/`,
          name: `Filet`,
        },
        {
          url: `/fil-tendu-anti-pigeons/`,
          name: `Fil tendu`,
        },
        {
          url: `/systeme-electro-repulsif-pigeons/`,
          name: `Électro-répulsif`,
        },
        {
          url: `/plaque-bouche-tuile-anti-pigeons/`,
          name: `Plaque bouche tuile`,
        },
        {
          url: `/treillis-soude-galvanise-anti-pigeons/`,
          name: `Treillis soudé galvanisé`,
        },
      ],
    },
    {
      url: `/nos-realisations/`,
      name: `Nos réalisations`,
    },
    {
      url: `/qui-sommes-nous/`,
      name: `Notre entreprise`,
    },
  ];
  const { site } = useStaticQuery(graphql`
    query SiteData {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header className="pb-4">
      <div className="pt-6 pb-6 shadow-lg">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
          <nav className="relative flex items-center justify-between sm:h-10 xl:justify-center">
            <div className="flex items-center flex-1 xl:absolute xl:inset-y-0 xl:left-0">
              <div className="flex items-center justify-between w-full xl:w-auto">
                <Link to="/" title={site.siteMetadata.title}>
                  <img
                    className="h-12 w-auto sm:h-16 mb-0"
                    src={Logo}
                    alt={site.siteMetadata.title}
                  />
                </Link>
                <div className="-mr-2 flex items-center xl:hidden">
                  <button
                    aria-label="Menu"
                    type="button"
                    onClick={() => toggleExpansion(!isExpanded)}
                    className="inline-flex items-center justify-center p-2 rounded-md text-green-400 hover:text-green-500 hover:bg-green-100 focus:outline-none focus:bg-green-100 focus:text-green-500 transition duration-150 ease-in-out"
                  >
                    <svg
                      className="h-6 w-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="hidden xl:block text-gray-900">
              {menuItems.map((link) => {
                return link.submenu ? (
                  <SubMenu link={link} key={link.name} />
                ) : (
                  <Link
                    className="relative mr-5 font-medium hover:text-green-500 focus:outline-none focus:text-green-500 transition duration-150 ease-in-out"
                    activeClassName="text-green-500"
                    key={link.name}
                    title={link.name}
                    to={link.url}
                  >
                    {link.name}
                  </Link>
                );
              })}
            </div>
            <div className="hidden xl:absolute xl:flex xl:items-center xl:justify-end xl:inset-y-0 xl:right-0">
              <span className="inline-flex rounded-md shadow">
                <Link
                  to="/contactez-nous/"
                  className="shadow-xl inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out"
                >
                  <svg
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    className="w-5 h-5 mr-2"
                  >
                    <path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                  </svg>
                  Devis et renseignements
                </Link>
              </span>
            </div>
          </nav>
        </div>

        <div
          className={`${
            isExpanded ? `block` : `hidden`
          } absolute top-0 left-0 right-0 md:left-auto p-2 md:w-1/2 xl:hidden z-50`}
        >
          <div className="rounded-lg shadow-md transition transform origin-top-right">
            <div className="rounded-lg bg-white shadow-xs overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <Link to="/" title={site.siteMetadata.title}>
                    <img
                      className="h-8 w-auto mb-0"
                      src={Logo}
                      alt={site.siteMetadata.title}
                    />
                  </Link>
                </div>
                <div className="-mr-2">
                  <button
                    aria-label="Fermer le menu"
                    type="button"
                    onClick={() => toggleExpansion(!isExpanded)}
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                  >
                    <svg
                      className="h-6 w-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3 text-gray-900">
                {menuItems.map((link) => (
                  <Link
                    className="block px-3 py-2 rounded-md text-base font-medium hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                    activeClassName="text-green-500"
                    key={link.name}
                    title={link.name}
                    to={link.url}
                  >
                    {link.name}
                  </Link>
                ))}
              </div>
              <div>
                <Link
                  to="/contactez-nous/"
                  title="Contactez-nous"
                  className="block w-full px-5 py-3 text-center font-medium text-green-600 bg-gray-50 hover:bg-gray-100 hover:text-green-700 focus:outline-none focus:bg-gray-100 focus:text-green-700 transition duration-150 ease-in-out"
                >
                  Devis et renseignements
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
